import { Injectable } from '@angular/core';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';
import {
  SlaContractDetailsDTO,
  convertResponseToSlaContractDetailsDTO,
} from '../dto/sla-contract.dto';
import { SlaContractDetailsConfig } from '../config/sla-contract-details.config';
import { SlaContractService } from '../services/sla-contract.service';
import { DetailsBaseStore } from '../../../../core/store/details-base.store';
import { action, makeObservable, observable } from 'mobx';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';

@Injectable({ providedIn: 'root' })
export class SlaContractDetailsStore extends DetailsBaseStore<SlaContractDetailsDTO> {
  tabs: DetailsTab[] = [];
  breadcrumbs: BreadcrumbItem[] = [];

  constructor(
    private slaContractDetailsConfig: SlaContractDetailsConfig,
    private slaContractService: SlaContractService,
    private currentUserPreferenceStore: CurrentUserPreferenceStore
  ) {
    super();
    makeObservable(this, {
      tabs: observable,
      breadcrumbs: observable,
      resetEntireState: action,
      convertResponseToDTO: action,
    });
    this.tabs = slaContractDetailsConfig.slaTabs;
    this.breadcrumbs = slaContractDetailsConfig.breadcrumbs;
  }

  override get service(): any {
    return this.slaContractService;
  }

  override resetEntireState(): void {
    this.isLoading = false;
    this.breadcrumbs = [];
    this.tabs = [];
    this.details = {} as SlaContractDetailsDTO;
  }

  convertResponseToDTO(response: any) {
    const complianceDetails = convertResponseToSlaContractDetailsDTO(response, this.currentUserPreferenceStore.languageKey);
    const { reference_number, title } = complianceDetails;
    this.breadcrumbs[1].label = `${reference_number} - ${title}`;
    return complianceDetails;
  }
}
